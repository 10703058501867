import React, { useState } from "react";
import useSound from "use-sound";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import axios from "axios";
import './Post.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faSnowflake } from '@fortawesome/free-solid-svg-icons';

const Post = ({ post }) => {
    const [ likes, setLikes ] = useState(post.likes);
    const [ liked, setLiked ] = useState(false);

    const [ disLikes, setDisLikes ] = useState(0);
    const [ disLiked, setDisLiked ] = useState(false);

    const timeAgo = (dateString) => {
        const now = new Date();
        const postDate = new Date(dateString);
        const diffInSeconds = Math.floor((now - postDate) / 1000);

        const units = [
            { label: "year", seconds: 31536000 },
            { label: "month", seconds: 2592000 },
            { label: "day", seconds: 86400 },
            { label: "hour", seconds: 3600 },
            { label: "minute", seconds: 60 },
            { label: "second", seconds: 1 },
        ];
    
        for (let unit of units) {
            const interval = Math.floor(diffInSeconds / unit.seconds);
            if (interval >= 1) {
                return `${interval} ${unit.label}${interval > 1 ? 's' : ''} ago`;
            }
        }
        return "Just now";
    };

    const likeHandler = async (event) => {
        event.preventDefault();
        if (disLiked) {
            setDisLikes(disLikes - 1);
            setDisLiked(false);
        }

        const newLikes = liked ? likes - 1 : likes + 1;
        setLikes(newLikes);
        setLiked(!liked);

        try {
            await axios.post(`/api/like`, {
                postID: post.ID
            },
        {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        });
        } catch (error) {
            console.error(error);
        }
    };

    const disLikeHandler = async (event) => {
        event.preventDefault();
        if (liked) {
            setLikes(likes - 1);
            setLiked(false);
        }

        const newDisLikes = disLiked ? disLikes - 1 : disLikes + 1;
        setDisLikes(newDisLikes);
        setDisLiked(!disLiked);

        try {
            await axios.post(`/api/disLike`, {
                postID: post.ID
            },
        {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="post card bg-dark text-light shadow-sm rounded-4">
            <div className="card-body">
                <h3 className="post-title">{post.title}</h3>
                <Link to={`/profile/${post.username}`} className="userlink text-secondary">{post.username}</Link>
                <p className="post-date small">{timeAgo(post.date)}</p>
                <p className="post-body">{post.body}</p>
                <div className="d-flex justify-content-start gap-3 mt-3">
                <button 
                    onClick={likeHandler} 
                    className={`likebutton rounded-3 ${liked ? "fire likebuttonclicked" : ""}`}

                >
                    {likes} <FontAwesomeIcon icon={faFire} />
                </button>
                <button 
                    onClick={disLikeHandler} 
                    className={`dislikebutton rounded-3 ${disLiked ? "dislikebuttonclicked" : ""}`}
                >
                    {disLikes} <FontAwesomeIcon icon={faSnowflake} />
                </button>
                </div>
            </div>
        </div>
    );
};

export default Post;