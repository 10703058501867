import React, { useContext } from 'react';
import UserContext from '../UserContext';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

const Navbar = () => {
    const { isAuthenticated, setIsAuthenticated, username } = useContext(UserContext);

    const handleLogout = () => {
        localStorage.removeItem("token");
        setIsAuthenticated(false);
      };

    return (
      <nav className="navbar navbar-expand-lg navbar-custom">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand d-flex align-items-center me-auto">
          <img src="drawing.svg" alt="logo" className="logo-image" />
          <span className="ms-2">Xitter</span>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav auth-links">
            {isAuthenticated ? (
              <>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={handleLogout}>Logout</button>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/createpost">Create Post</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/profile/${username}`}>
                    <FontAwesomeIcon icon={faUser} size="2x" />
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">Login</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/register">Register</Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
    )
};

export default Navbar;