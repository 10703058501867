import React, { useState } from 'react';
import { useParams } from "react-router-dom";

const VideoPlayer = () => {
    const episode = useParams().episode;
    return (
        <div>
            <video controls width="800">
                <source src={`/api/video/${episode}`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default VideoPlayer;


//temp